@import '../variables/index.less';

.zast-blue-table {
  .ant-table-container {
    border: none !important;
    .ant-table-header {
      margin-right: -2px;
      .ant-table-thead {
        tr {
          > th {
            background-color: @calendarBlue !important;
            color: white !important;
            font-family: @BoldFont;
            // border-right: 1px solid @grayLight;
            &:first-child {
              border-radius: 4px 0 0 4px !important;
            }
            &:last-child {
              border-radius: 0 4px 4px 0 !important;
            }
          }
        }
      }
    }
    .ant-table-body {
      table > {
        border-spacing: 0 10px;
        margin-top: -10px;
        tbody > {
          tr {
            background-color: @white !important;
            border-radius: 5px;
            box-shadow: 2px 2px 0 0 rgba(38, 38, 38, 0.16);
            &:first-child {
              //display: none;
            }
            td > {
              padding: 12px;
            }
            &:nth-child(even) {
              background-color: #f9f9f9;
            }
            td:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
            td:last-child {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }
      }
    }
  }
}
