@import '~antd/dist/antd.less';
@import './antd/custom.less';
@import './antd/override.less';
@import './variables/index.less';
@import './common/index.less';

@font-face {
  font-family: 'Roboto-Regular';
  src: url(../assets/fonts/Roboto-Regular.ttf) format('truetype'),
    local('Helvetica Neue'), local('HelveticaNeue'), local('Arial');
  //src: local('Arial');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url(../assets/fonts/Roboto-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url(../assets/fonts/Roboto-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-Light';
  src: url(../assets/fonts/Roboto-Light.ttf) format('truetype');
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: @silver !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //
  //::-webkit-scrollbar {
  //  width: 0.4em;
  //}
  ///* Track */
  //::-webkit-scrollbar-track {
  //  background: #f1f1f1;
  //}
  ///* Handle */
  //::-webkit-scrollbar-thumb {
  //  background: #888;
  //}
  ///* Handle on hover */
  //::-webkit-scrollbar-thumb:hover {
  //  background: #555;
  //}

  .ant-layout {
    background: @white;
  }
  img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}

