@import '../variables/index.less';

.ant-btn {
  &.zast-primary-btn {
    height: 32px;
    border-radius: 6px;
    border: none;
    font-size: 12px;
    font-family: @BoldFont;
    &.light-green {
      background-color: @lightGreen;
      color: @white;
    }
    &.green {
      background-color: @green;
      color: @white;
    }
    &.red {
      background-color: @terminateRed;
      color: @white;
    }
    &.light-gray {
      background-color: #d6d6d6;
    }
    &.gray {
      background-color: @gray;
    }
    &.yellow {
      background-color: @yellow;
      color: @white;
    }
    &.dark-blue {
      background-color: @darkBlue;
      color: @white;
      &:hover {
        // box-shadow: 0px 0px 1px 3px #c7cdd2;
      }
    }
    &.dark-blue-two-tone {
      color: @darkBlue;
      border: 1px solid @darkBlue;
      &:hover {
        //box-shadow: 0px 0px 1px 3px #c7cdd2;
      }
    }
    &.white {
      background-color: @white;
      color: @lightBlue;
      border: 1px solid @lightBlue;
      // box-shadow: 0px 0px 1px 3px #c7cdd2;
      &:hover {
        box-shadow: 0px 0px 1px 3px #d7e6f3;
      }
    }
    &.light-blue {
      background-color: @lightBlue;
      color: @white;
      &:hover {
        box-shadow: 0px 0px 1px 3px #d7e6f3;
      }
    }
    &.black {
      background-color: @white;
      color: @darkBlue;
      border: 1px solid @darkBlue;
      &:hover {
        box-shadow: 0px 0px 1px 3px #c7cdd2;
      }
    }
    &.outline {
      background-color: @white;
      &.dark-blue {
        color: @darkBlue;
        border: 1px solid @darkBlue;
        &:hover {
          background-color: @darkBlue;
          color: @white;
        }
      }
      &.red {
        color: @red;
        border: 1px solid @red;
        &:hover {
          background-color: @red;
          color: @white;
        }
        &.selected {
          background-color: @red;
          color: @white;
        }
      }
      &.calendarGreen {
        color: @calendarGreen;
        border: 1px solid @calendarGreen;
        &.selected {
          background-color: @calendarGreen;
          color: @white;
        }
        &:hover {
          background-color: @calendarGreen;
          color: @white;
        }
      }
      &.calendarYellow {
        color: @calendarYellow;
        border: 1px solid @calendarYellow;
        &:hover {
          background-color: @calendarYellow;
          color: @white;
        }
        &.selected {
          color: @white;
          background-color: @calendarYellow;
        }
      }
      &.dark-blue {
        color: @darkBlue;
        border: 1px solid @darkBlue;
        // &:hover {
        //   background-color: @darkBlue;
        //   color: @white;
        // }
        &.selected {
          background-color: @darkBlue;
          color: @white;
        }
      }
    }
  }
}
