.zast-select {
  .ant-select-selector {
    height: 44px !important;
    display: flex;
    align-items: center;
    border-radius: 4px !important;
    .ant-select-selection-search {
      > input {
        height: 100% !important;
      }
    }
  }
}

.zast-multi-select {
  .ant-select-selector {
    min-height: 44px !important;
    display: flex;
    align-items: center;
    border-radius: 4px !important;
    .ant-select-selection-search {
      > input {
        height: 100% !important;
      }
    }
  }
}
