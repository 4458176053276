@import '../variables/index.less';
.ant-form-item-label {
  > label:after {
    content: '' !important;
  }
}

table,
tr,
td {
  border: none;
}

.ant-notification {
  .ant-notification-notice {
    border-radius: 4px;
  }
}

.ant-table-thead {
  tr {
    > th {
      background-color: #3a3c56 !important;
      color: white !important;
      // border-right: 1px solid @grayLight;
      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }
      &:last-child {
        border-radius: 0px 4x 4px 0px;
      }
    }
  }
}

// .ant-table-header {
//   table {
//     margin-bottom: 10px;
//   }
// }
// .ant-table-tbody {
//   tr {
//     td {
//       &:nth-child(1) {
//         td {
//           border-top: 0px solid @lightGrey !important;
//         }
//       }
//     }
//   }
// }
.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
}
span.ant-pagination-item-ellipsis {
  left: -5px !important;
}
.ant-pagination-item a {
  height: 100% !important;
}
